import * as React from 'react'
import { graphql } from 'gatsby'

export const DynamicQrLink = ({ slice }) => {
  const {title, btn_label, btn_link} = slice.primary
  const link = `${btn_link.url}/`
  return (
    <div className={"pt-5 bg-color-2"}>
		  <div className={"container bg-dark py-5 rounded-4"}>
        <div className="d-flex flex-column flex-xl-row justify-content-center align-items-center">
          <h3 className="text-white text-center mb-0">{title}</h3>
          <div style={{height:"24px",width:"24px"}}/>
          <a className="btn btn-light text-uppercase" href={link}>{btn_label}</a>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyDynamicQrLink on PrismicPageDataBodyDynamicQrLink {
    primary {
      title
      btn_label
      btn_link {
        url
      }
    }
  }
`
