import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const VersatileForms = ({ slice }) => {
  const {title} = slice.primary

  const items = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-12 col-lg-6">
        <div className="border border-light border-5 rounded-4 bg-light">
          <div  className="bg-light p-2 px-4">
				    <h3 className="mb-0">{item.title}</h3>
          </div>
          <img src={item.img.url} className="img-fluid rounded-4" alt={item.img.alt} />
        </div>
			</div>
    )
  })

  return (
    <div  className="my-6">
			<div className="container">
        <h2 className="text-center mb-5">{title}</h2>
				<div className="row g-4">
          {items}
				</div>
			</div>
		</div>
  )
}

export const query = graphql`
  fragment PageDataBodyVersatileForms on PrismicPageDataBodyVersatileForms {
    primary {
      title
    }
    items {
      title
      img {
        url
        alt
      }
    }
  }
`
