import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Form from 'react-bootstrap/Form';
import { Pricing } from '../components/Pricing';

export const PricingNew = ({ slice }) => {
  const {title, trial_btn_link} = slice.primary

	const [isSwitchOn, setIsSwitchOn] = useState(false);

	const onSwitchAction = () => {
	  setIsSwitchOn(!isSwitchOn);
	};

	console.log(slice.items)
	let isSubst = false;
	if (slice.items.length) {
		isSubst = slice.items[0].subscription;
	}
	for (let i = 0; i < slice.items.length; i++) {
		const el = slice.items[i];
		isSubst = isSubst && el.subscription;
	}

  return (
    <div className="pricing bg-color-3 py-6" id="pricing">
			<div className="container">
				<h2 className="text-center text-white mb-5">{title}</h2>
				{(isSubst) ? <></> :
				<div className="d-flex justify-content-center align-items-center text-white">
					<div className="d-flex flex-column justify-content-center align-items-center">
						<h4 className="mb-0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"SaaS (Hosted by Us)"}</h4>
					</div>
					<div className="px-4">
						<Form>
    				  <Form.Check
								type="switch"
    				    onChange={onSwitchAction}
								checked={isSwitchOn}
    				  />
    				</Form>
					</div>
					<div className="d-flex flex-column justify-content-center align-items-center">
						<h4 className="mb-0">{"Hosted in Client's AWS Account"}</h4>
					</div>
				</div>}
				<Pricing items={slice.items} isSwitchOn={isSwitchOn}/>
			</div>
		</div>
  )
}

export const query = graphql`
	fragment HomepageDataBodyPricingNew on PrismicHomepageDataBodyPricingNew {
    primary {
      title
			trial_btn_link {
				url
			}
    }
    items {
			subscription
      title
			main_features {
				richText
			}
			extra_feature_1
			extra_feature_1_popover_label
			extra_feature_1_popover_text
			extra_feature_2
			extra_feature_2_popover_label
			extra_feature_2_popover_text
			btn_text
			btn_link {
				url
			}
			text_after_btn
			text_bottom {
				richText
			}
    }
  }
	fragment PageDataBodyPricingNew on PrismicPageDataBodyPricingNew {
    primary {
      title
			trial_btn_link {
				url
			}
    }
    items {
			subscription
      title
			main_features {
				richText
			}
			extra_feature_1
			extra_feature_1_popover_label
			extra_feature_1_popover_text
			extra_feature_2
			extra_feature_2_popover_label
			extra_feature_2_popover_text
			btn_text
			btn_link {
				url
			}
			text_after_btn
			text_bottom {
				richText
			}
    }
  }
`
