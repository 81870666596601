import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Partners = ({ slice }) => {
  const {title} = slice.primary

  const images = slice.items.map((item, index) => {
    return (
      <div key={index}>
        <a href={item.link.url} target="_blank"><img src={item.img.url} alt={item.img.alt} className="img-fluid" loading="lazy"/></a>
			</div>
    )
  })

  return (
    <div className={"bg-light py-6"}>
      <div className="container text-center">
        <h3 className="text-center mb-4">{"Proud participant of Stoff im Kopf textile startup accelerator"}</h3>
        <div className="d-flex justify-content-center align-items-center g-4">
          {images}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyPartners on PrismicPageDataBodyPartners {
    primary {
      title
    }
    items {
      img {
        url
        alt
      }
      link {
        url
      }
    }
  }
`
