import * as React from 'react'
import { graphql } from 'gatsby'
import Accordion from 'react-bootstrap/Accordion';
import { PrismicRichText } from '@prismicio/react'

export const AccordionFaq = ({ slice }) => {
  const {title} = slice.primary
  const faq = slice.items.map((item, index) => {
    return (
      <Accordion.Item eventKey={index} key={index} >
        <Accordion.Header as="h3"><span className="h5 mb-0">{item.faq_title}</span></Accordion.Header>
        <Accordion.Body>
          <PrismicRichText field={item.faq_text.richText} />
        </Accordion.Body>
      </Accordion.Item>
    )
  })

  return (
    <div className={"container py-6"}>
      <h2 className="text-center mb-5">{title}</h2>
      <div className="mt-4">
        <Accordion>
          {faq}
        </Accordion>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyAccordionFaq on PrismicHomepageDataBodyAccordionFaq {
    primary {
      title
    }
    items {
      faq_title
      faq_text {
        richText
      }
    }
  }
  fragment PageDataBodyAccordionFaq on PrismicPageDataBodyAccordionFaq {
    primary {
      title
    }
    items {
      faq_title
      faq_text {
        richText
      }
    }
  }
`
