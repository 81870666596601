import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const FeaturesNew = ({ slice }) => {

  const features = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-12 col-lg-4">
        <div  className="bg-light rounded-4 h-100 p-4 d-flex flex-column justify-content-center align-items-center">
          <div className="rounded-3 d-flex justify-content-center align-items-center mb-3 bg-dark" style={{height:"64px",width:"64px"}}>
            <img src={item.img.url} className="img-fluid" alt={item.img.alt} height="40" width="40"/>
          </div>
				  <h4 className="mb-0">{item.title}</h4>
        </div>
			</div>
    )
  })

  return (
    <div  className={`pt-6 ${slice.primary.pb_0 ? "" : "pb-6"}`} id="features">
			<div className="container">
				<div className="row g-4">
          {features}
				</div>
			</div>
		</div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyFeaturesNew on PrismicHomepageDataBodyFeaturesNew {
    primary {
      pb_0
    }
    items {
      title
      img {
        url
        alt
      }
    }
  }
  fragment PageDataBodyFeaturesNew on PrismicPageDataBodyFeaturesNew {
    primary {
      pb_0
    }
    items {
      title
      img {
        url
        alt
      }
    }
  }
`
