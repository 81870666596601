import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Accordion from 'react-bootstrap/Accordion';

export const CardFormats = ({ slice }) => {
  const {title} = slice.primary

  const [format,setFormat] = useState(0)

  const formats = slice.items.map((item, index) => {
    return (
        <Accordion.Item key={index} eventKey={index} onClick={() => {setFormat(index);}}>
          <Accordion.Header as="h3"><span className="h5 mb-0">{item.title}</span></Accordion.Header>
          <Accordion.Body>
            <div>{item.subtitle}</div>
            <img src={slice.items[format].img.url} alt={slice.items[format].img.alt} className="d-block d-lg-none mt-4 img-fluid rounded img-shadow" loading="lazy"/>
          </Accordion.Body>
        </Accordion.Item>
    )
  })

  return (
    <div className={"container py-6"}>
      <h2 className="text-center mb-5">{title}</h2>
      <div className="mt-4">
        <div className="row">
          <div className="col-12 col-lg-5">
            <div >
              <Accordion defaultActiveKey={0} className="card-formats-accordion d-grid gap-3">
                {formats}
              </Accordion>
            </div>
          </div>
          <div className="col-7 d-none d-lg-flex flex-column justify-content-center align-items-center" style={{height:"600px"}}>
            <img src={slice.items[format].img.url} alt={slice.items[format].img.alt} className="img-fluid rounded-4 img-shadow" loading="lazy"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyCardFormats on PrismicPageDataBodyCardFormats {
    primary {
      title
    }
    items {
      title
      subtitle
      img {
        url
        alt
      }
    }
  }
`
