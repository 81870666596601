import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'

export const HeroBgImage = ({ slice }) => {
  const {title, img} = slice.primary

  const buttons = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-auto">
		  	<a href={item.btn_link.url} className={"btn text-uppercase" + " " + ((index == 0) ? "btn-primary" : "btn-light")}>{item.btn_label}</a>
		  </div>
    )
  })

  return (
    <div id="top" className="header-1 d-flex flex-column py-5" style={{backgroundImage:"url(" + img.url + ")",backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center center"}}>
      <div className="py-5 d-flex flex-grow-1 flex-column justify-content-center">
		  	<div className="container h-100 d-flex flex-column align-items-center justify-content-center">
          <h1 className="header-title-1 mb-4">{title}</h1>
		  		<div className="d-flex justify-content-around row g-3">
		  			{buttons}
		  		</div>
		  	</div>
		  </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyHeroBgImage on PrismicPageDataBodyHeroBgImage {
    primary {
      title
      img {
        url
      }
    }
    items {
      btn_label
      btn_link 
      {
        url
      }
    }
  }
`
