import * as React from 'react';
import { useContext, useEffect, useState,useReducer,useMemo, useRef } from 'react';
import { useForm } from "react-hook-form";
import { PrismicRichText } from '@prismicio/react'
// import validator from 'validator';

import settings from '../settings.json';
import {getFromApi} from '../utils/utils';

export const ClientInfoHookForm = ({ slice }) => {
  const {subtitle, text, text_after_1, text_after_2} = slice.primary

  const [isSend, setSend] = useState(false);
  const [config, setConfig] = useState({});
  const [email, setEmail] = useState(null);

  const { register, handleSubmit, watch, setError, formState: { errors, isSubmitted } } = useForm({
    mode: "onChange"
  });
  const onSubmit = async (data) => {
    //console.log('settings: ', settings);
    //console.log('data: ', data);
    const url = `${settings.colibri}/email`;
    const params = {email:data.email};
    const r = await getFromApi({url,params,method:'POST'});
    //console.log(r);
    if (r.success === true) {
        // const {domain, email} = r.config;
        // setConfig({domain, email});
        //console.log('Success');
    }
    setEmail(data.email);
    setSend(true);
  }

  if (isSend) {
    // const d = `https://${config.domain}/dashboard/index.html`;
    return (
      <div className="mt-4 text-center">
        <p>{text_after_1.text + " "}<b>{email}</b>{"."}</p>
        <p className="fs-0875">{text_after_2.text}</p>
        {/* <p>{"Your registered e-mail is "}<span className="fw-bold">{config.email}</span></p> */}
        {/* <p>{"Your dashboard url is "}<a href={d}>{d}</a></p> */}
        <div className="">
          <button onClick={()=>setSend(false)} className={"btn btn-sm text-uppercase btn-primary"}>{"Try again"}</button>
        </div>
      </div>
    );
  }

  

  // console.log(watch("example")); // watch input value by passing the name of it
  //console.log('errors: ', errors)
  return (
    <div className="mt-4 col-12 col-md-8 col-lg-6 mx-auto">
			<p className="text-center">{subtitle}</p>
      <PrismicRichText field={text.richText} />
      <form className="text-center" onSubmit={handleSubmit(onSubmit)}>
        {/* <h1>Try it yourself!</h1> */}
        <div className="">
          <input
            className={'form-control text-center' + " " + (errors.full && "is-invalid")}
            placeholder='Email address'
            {...register('email', {required: true})}
          />
          <div className="invalid-feedback" wfd-invisible="true">{'Invalid client id'}</div>
        </div>
        <input type="submit" value="Submit" className="mt-4 btn btn-sm btn-primary text-uppercase"/>
      </form>
    </div>
  );
}