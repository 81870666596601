import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'

export const EmbedHtml = ({ slice }) => {
  const {title, html} = slice.primary

  const id = title.replace(/\s+/g, '-').toLowerCase();

  return (
    <div id={id} className={"py-6 bg-white"}>
      <div className="container">
        <h2 className="text-center mb-5">{title}</h2>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyEmbedHtml on PrismicPageDataBodyEmbedHtml {
    primary {
      title
      html
    }
  }
`
