import * as React from 'react'
import { graphql } from 'gatsby'

export const CustomerReviews = ({ slice }) => {
  const {title, img, link, margin_top} = slice.primary

  return (
    <div className={"bg-white d-flex justify-content-center align-items-center" + " " + (margin_top ? "pt-6" : "")}>
      <h3 className="text-center mb-0 me-1">{title}</h3>
      <a href={link.url}><img src={img.url} alt={img.alt} className="img-fluid" loading="lazy"/></a>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyCustomerReviews on PrismicHomepageDataBodyCustomerReviews {
    primary {
      title
      img {
        url
        alt
      }
      link {
        url
      }
    }
  }
  fragment PageDataBodyCustomerReviews on PrismicPageDataBodyCustomerReviews {
    primary {
      margin_top
      title
      img {
        url
        alt
      }
      link {
        url
      }
    }
  }
`
