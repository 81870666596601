import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Features5 = ({ slice }) => {
  const {title} = slice.primary

  const id = title.replace(/\s+/g, '-').toLowerCase();

  const features = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-12 col-lg-4 mx-auto">
        <div className="bg-white rounded h-100 overflow-hidden text-center shadow p-5">
          <img src={item.img.url} className="img-fluid mb-4" style={{height:"40px"}} alt={item.img.alt} />
          <div className="">
				    <h4 className="text-center">{item.title}</h4>
            <div className="no-mb-richtext">
              <PrismicRichText field={item.text.richText} className="mb-0"/>
            </div>
          </div>
        </div>
			</div>
    )
  })

  return (
    <div  className="py-6" id={id}>
			<div className="container">
        <h2 className="text-center mb-5">{title}</h2>
				<div className="row g-5 px-xl-5">
          {features}
				</div>
			</div>
		</div>
  )
}

export const query = graphql`
  fragment PageDataBodyFeatures5 on PrismicPageDataBodyFeatures5 {
    primary {
      title
    }
    items {
      title
      text {
        richText
      }
      img {
        url
        alt
      }
    }
  }
`
