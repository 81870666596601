const TRANS = {
	"en": {
		"qr_field_url":"Your link here",
		"qr_field_firstName":"First name",
		"qr_field_lastName":"Last name",
		"qr_field_mobile":"Mobile",
		"qr_field_phone":"Phone",
		"qr_field_fax":"FAX",
		"qr_field_email":"Email",
		"qr_field_company":"Company",
		"qr_field_job":"Your Job",
		"qr_field_zip":"ZIP",
		"qr_field_website":"www.your-website.com",
		"qr_field_street":"Street",
		"qr_field_city":"City",
		"qr_field_state":"State",
		"qr_field_country":"Country",
		"qr_field_create": "Create vCard",
		"qr_field_photo":"Photo URL",
		"qr_field_facebook":"Facebook",
		"qr_field_twitter":"Twitter",
		"qr_field_instagram":"Instagram",
		"qr_field_youtube":"YouTube",
		"qr_field_linkedin":"LinkedIn",
		"qr_field_label":"Label",
	}
}

const translate = (txt, lang='en') => {
    let words = TRANS[lang];
	if (!words) {
        words = TRANS['en'];
    }
    const word = words[txt];
    return word || txt;
}
export default translate;
