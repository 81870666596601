import * as React from 'react'
import { graphql } from 'gatsby'
import ShortHookForm from '../components/ShortHookForm';

export const GetColibriPage = (props) => {
  //console.log('props: ', props);
  const { slice, context } = props;
  const {title, subtitle, cover_img, title2, subtitle2} = slice.primary

  const title_pt1 = title.split(/\n/)[0]
  const title_pt2 = title.split(/\n/)[1]


  return (
    <div className="row g-0">
      <div className="col-12 col-xxl-6 header-get-yohn d-flex flex-column py-5" style={{background: "linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(" + cover_img.url + ")",backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center center"}}>
        <div className="py-5 d-flex flex-grow-1 flex-column justify-content-center">
		    	<div className="container h-100 d-flex flex-column align-items-center justify-content-center">
            <h1 className="header-title-1 mb-4">{title_pt1}<br/>{title_pt2}</h1>
		    		<span className="header-title-2">{subtitle}</span>
		    	</div>
		    </div>
      </div>
      <div className="col-12 col-xxl-6 py-5 px-3 bg-color-1 d-flex flex-column justify-content-center">
	    	<h2 className="text-center mb-5">{title2}</h2>
	    	<p className="text-center">{subtitle2}</p>
        <ShortHookForm />
	    </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyGetColibriPage on PrismicPageDataBodyGetColibriPage {
    primary {
      title
      subtitle
      cover_img {
        url
        alt
      }
      title2
      subtitle2
    }
  }
`
