import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const BlogText = ({ slice }) => {
  const {text} = slice.primary

  return (
      <div className="container px-0 py-4">
        <PrismicRichText field={text.richText} />
      </div>
  )
}

export const query = graphql`
  fragment BlogItemDataBodyBlogText on PrismicBlogItemDataBodyBlogText {
    slice_type
    primary {
      text {
        richText
      }
    }
  }
`
