import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Features = ({ slice }) => {
  const {title, subtitle, secondary_bg} = slice.primary

  const features = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-lg-4 d-flex flex-column align-items-center text-center px-4 mb-5 mb-lg-0">
				<img src={item.img.url} className="img-fluid mb-4" alt={item.img.alt} height="80" width="80"/>
				<h5>{item.title}</h5>
				<PrismicRichText field={item.text.richText} />
			</div>
    )
  })

  return (
    <div  className={secondary_bg ? "bg-color-1 py-6" : "my-6"} id="features">
			<div className="container">
				<h2 className="text-center mb-5">{title}</h2>
        {subtitle && <h4 className="text-center mb-5">{subtitle}</h4>}
				<div className="row">
          {features}
				</div>
			</div>
		</div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyFeatures on PrismicHomepageDataBodyFeatures {
    primary {
      secondary_bg
      title
      subtitle
    }
    items {
      title
      text {
        richText
      }
      img {
        url
        alt
      }
    }
  }
  fragment PageDataBodyFeatures on PrismicPageDataBodyFeatures {
    primary {
      secondary_bg
      title
      subtitle
    }
    items {
      title
      text {
        richText
      }
      img {
        url
        alt
      }
    }
  }
`
