import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'

export const MainCover = ({ slice }) => {
  const {title, subtitle, cover_img} = slice.primary
  const [titleData, setTitleData] = useState({isReady:false});

  useEffect(() => {
    let custom_title = ""
    if (typeof window !== 'undefined'){
      const params = new URLSearchParams(window.location.search.substring(1));
      custom_title = params.get("title");
    }  
    //console.log("title: ", title)
    //console.log("custom_title: ", custom_title)
    if (custom_title) {
      setTitleData({isReady:true, isCustom:true, title:custom_title});
    } else {
      const title_pt1 = title.split(/\n/)[0]
      const title_pt2 = title.split(/\n/)[1]
      setTitleData({isReady:true, isCustom:false, title1:title_pt1, title2:title_pt2});
    }
  },[])
  //console.log('.')
  const buttons = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-auto">
		  	<a href={"#" + item.btn_anchor} className={"btn text-uppercase" + " " + (item.primary ? "btn-primary" : "btn-light")}>{item.btn_label}</a>
		  </div>
    )
  })

  return (
    <div id="top" className="header-1 d-flex flex-column py-5" style={{background: "linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(" + cover_img.url + ")",backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center center"}}>
      <div className="py-5 d-flex flex-grow-1 flex-column justify-content-center">
        {titleData.isReady && (
		  	<div className="container h-100 d-flex flex-column align-items-center justify-content-center">
          {titleData.isCustom ?
            <h1 className="header-title-1 mb-4">{titleData.title}</h1>
            :
            <h1 className="header-title-1 mb-4">{titleData.title1}<br/>{titleData.title2}</h1>
          }
		  		<span className="header-title-2 mb-5">{subtitle}</span>
		  		<div className="d-flex justify-content-around row g-3">
		  			{buttons}
		  		</div>
		  	</div>
        )}
		  </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyMainCover on PrismicHomepageDataBodyMainCover {
    primary {
      title
      subtitle
      cover_img {
        url
      }
    }
    items {
      btn_label
      btn_anchor
      primary
    }
  }
  fragment PageDataBodyMainCover on PrismicPageDataBodyMainCover {
    primary {
      title
      subtitle
      cover_img {
        url
      }
    }
    items {
      btn_label
      btn_anchor
      primary
    }
  }
`
