import * as React from 'react'
import { graphql } from 'gatsby'
import * as prismicH from "@prismicio/helpers";
import { PrismicRichText } from '@prismicio/react'

export const ImagesAndTextNew = ({ slice }) => {
  const {title} = slice.primary

  let evenOrOdd = 0

  /*if (reverse) {
    evenOrOdd = 1
  }*/

  const imgsAndText = slice.items.map((item, index) => {

    let img_url = item.img.url
  
    if (new URL(img_url).pathname.endsWith(".gif")) {
      img_url= prismicH.asImageSrc(item.img, {auto: "compress"})
    }

    return (
      <div key={index} className="bg-light rounded-4">
      <div className={`row g-0 ${(index+1<slice.items.length) ? `mb-5` : ``}`}>
				<div className={"col-lg-6 d-flex flex-column justify-content-center" + " " + ((index % 2 != evenOrOdd) ? "order-lg-2" : "")}>
          <div className="p-4 p-md-5">
					  <h2 className="h3">{item.title}</h2>
					  <PrismicRichText field={item.text.richText} />
            {item.btn_label &&<a href={item.btn_link.url} className={"btn text-uppercase btn-dark mt-3"}>{item.btn_label}</a>}
          </div>
				</div>
				<div className={"col-lg-6 d-flex flex-column justify-content-end align-items-center" + " " + ((index % 2 != 0) ? "order-lg-1" : "") + "d-flex px-4 py-4 py-md-5" + " " + ((index % 2 != 0) ? "ps-md-5 pe-md-0" : "ps-md-0 pe-md-5")}>
					  <img src={img_url} className="img-fluid" style={{maxHeight:"360px"}} alt={item.img.alt} loading="lazy"/>
				</div>
			</div>
      </div>
    )
  })

  return (
    <div className={"py-6"} id="why">
			<div className="container">
				{title && <h2 className="text-center mb-5">{title}</h2>}
        {imgsAndText}
			</div>
		</div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyImagesAndTextNew on PrismicHomepageDataBodyImagesAndTextNew {
    primary {
      title
    }
    items {
      title
      text {
        richText
      }
      btn_label
      btn_link {
        url
      }
      img {
        url
        alt
      }
    }
  }
  fragment PageDataBodyImagesAndTextNew on PrismicPageDataBodyImagesAndTextNew {
    primary {
      title
    }
    items {
      title
      text {
        richText
      }
      btn_label
      btn_link {
        url
      }
      img {
        url
        alt
      }
    }
  }
`
