import * as React from 'react'
import { graphql } from 'gatsby'

export const CustomOffer = ({ slice }) => {
  const {title, subtitle, btn_label, btn_link, img} = slice.primary

  return (
    <div className={"py-6"}>
		  <div className={"container bg-dark rounded-4"}>
        <div className="row">
          <div className="col-6">
            <div className="p-4 p-md-5 text-white">
              <div className="bg-light text-dark rounded-3 p-2 px-3 mb-3" style={{width:"fit-content"}}>
                <h3 className="display-inline-block mb-0">{title}</h3>
              </div>
              <h4 className="">{subtitle}</h4>
              <a className="btn btn-primary text-uppercase mt-4" href={btn_link.url}>{btn_label}</a>
            </div>
          </div>
          <div className="col-6">
            <div className="px-4 pt-4 px-md-5 pt-md-5">
              <img src={img.url} className="img-fluid w-100" style={{maxHeight:"360px"}} alt={img.alt} title={img.alt} loading="lazy"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyCustomOffer on PrismicHomepageDataBodyCustomOffer {
    primary {
      title
      subtitle
      btn_label
      btn_link {
        url
      }
      img {
        url
      }
    }
  }
`
