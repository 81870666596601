import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const SuccessfulPurchase = ({ slice, index }) => {
  const {title, subtitle, text, img} = slice.primary

  useEffect(() => {
    if (typeof window !== 'undefined'){
      const params = new URLSearchParams(window.location.search.substring(1));
      const item_name = params.get("package");
      const price = Number(params.get("price"));      

    // create a new datalyer, or let it be empty
        window.dataLayer = window.dataLayer || [];

    //reset datalayer if length greater than 150, otherwise might cause issues
    if(window.dataLayer.length >150){
        window.dataLayer = []

        // this is how the documentation says to flush the datalayer, but is not working
        // so using the above line where dataLayer = []

        /*window.dataLayer.push(function() {
            this.reset();
        })*/
    }

    if (item_name && price) {
      //console.log("Package: ", item_name)
      //console.log("Price: ", price)
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          currency: "EUR",
          value: price,
          items: [
            {
              item_name: item_name,
              price: price,
              currency: "EUR",
            }
          ]
        }
      });
    }
  }}, []);

  const isFirst = index === 0;

  return (
    <div className="container py-6 text-center">
      <img src={img.url} alt={img.alt}/>
      {isFirst ? <h1 className="h2 my-4 text-center">{title}</h1> : <h2 className="my-4 text-center">{title}</h2>}
      <h4 className="text-center">{subtitle}</h4>
      <div className="text-start">
        <PrismicRichText field={text.richText} />
      </div>
      <img src="https://ct.capterra.com/capterra_tracker.gif?vid=2228540&vkey=af99ef8d1d876ce680303044f46f1971" />
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodySuccessfulPurchase on PrismicPageDataBodySuccessfulPurchase {
    primary {
      title
      subtitle
      text {
        richText
      }
      img {
        url
        alt
      }
    }
  }
`
