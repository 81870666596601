import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const HeroSection = ({ slice }) => {
  const {title_h1, subtitle, img} = slice.primary

  const buttons = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-auto">
        <a href={item.btn_link_anchor} target={item.btn_link_anchor.startsWith("#") ? "_self" : "_blank"} className={"btn text-uppercase" + " " + (item.primary ? "btn-primary" : "btn-dark")}>{item.btn_label}</a>
      </div>
    )
  })

  return (
    <div id="top" className="bg-white d-flex flex-column justify-content-center align-items-center" style={{/*minHeight:"calc(100vh - 80px)"*/}}>
        <div className="container hero-title text-center py-6">
          <PrismicRichText field={title_h1.richText} />
          {/*(title_h1.text.length > 0) ?       
            <PrismicRichText field={title_h1.richText} />
            :
            <h1><a href="/qr/">QR codes</a>, <a href="/branded-url-shortener/">Short URLs</a> and <a href="/digital-business-cards/">Digital Business Cards</a>.</h1>
          */}
          <h2 className="my-4 my-md-5">{subtitle}</h2>
          <div className="row g-4 justify-content-center mb-5">
            {buttons}
          </div>
          <img src={img.url} className="img-fluid mt-5" alt={img.alt} />
        </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyHeroSection on PrismicHomepageDataBodyHeroSection {
    primary {
      title_h1 {
        richText
        text
      }
      subtitle
      img {
        url
        alt
      }
    }
    items {
      btn_label
      btn_link_anchor
      primary
    }
  }
`
