import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const PageCover = ({ slice }) => {
  const {title, subtitle, text, img} = slice.primary

  const buttons = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-auto">
		  	<a href={"#" + item.btn_anchor} className={"btn text-uppercase" + " " + (item.primary ? "btn-primary" : "btn-light")}>{item.btn_label}</a>
		  </div>
    )
  })

  return (
    <div id="top" className="py-5 d-flex flex-column justify-content-center" style={{minHeight:"70vh"}}>
			<div className="container py-5">
				<div className="row">
					<div className="col-lg-7 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1">
						<h1>{title}</h1>
						<h5 className="mt-3">{subtitle}</h5>
            <PrismicRichText field={text.richText} />
            <div className="d-flex row g-3 mt-0">
		  			  {buttons}
		  		  </div>
					</div>
					<div className="col-lg-5 order-1 order-lg-2 d-flex flex-column justify-content-center">
						<img src={img.url} className="img-fluid mx-auto ml-lg-auto mr-lg-0" alt={img.alt} height="360" width="360"/>
					</div>
				</div>
			</div>		
		</div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyPageCover on PrismicHomepageDataBodyPageCover {
    primary {
      title
      subtitle
      text {
        richText
      }
      img {
        url
        alt
      }
    }
    items {
      btn_label
      btn_anchor
      primary
    }
  }
  fragment PageDataBodyPageCover on PrismicPageDataBodyPageCover {
    primary {
      title
      subtitle
      text {
        richText
      }
      img {
        url
        alt
      }
    }
    items {
      btn_label
      btn_anchor
      primary
    }
  }
`
