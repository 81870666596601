import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { ClientInfoHookForm } from '../components/ClientInfoHookForm';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

export const ClientInfoForm = ({ slice, context, index }) => {

  const pageUrl = context.url;
  //console.log('page url: ', pageUrl);

  const isFirst = index === 0;

  return (
    <div className="py-6 bg-color-1">
		<div className="container">
      {isFirst ? <h1 className="h2 text-center mb-5">{slice.primary.title}</h1> : <h2 className="text-center mb-5">{slice.primary.title}</h2>}
      <ClientInfoHookForm slice={slice}/>	
		</div>
	</div>
  )
}

export const query = graphql`
  fragment PageDataBodyClientInfoForm on PrismicPageDataBodyClientInfoForm {
    primary {
      title
      subtitle
      text {
        richText
      }
      text_after_1 {
        text
      }
      text_after_2 {
        text
      }
    }
  }
`
