import * as React from 'react'
import { graphql } from 'gatsby'
import ShortHookForm from '../components/ShortHookForm';

export const ShortForm = (props) => {
  //console.log('props: ', props);
  const { slice, context } = props;
  const pageUrl = context.url;
  //console.log('page url: ', pageUrl);
  const {high} = slice.primary


  return (
  <div className={"py-5" + " " + (high ? "bg-color-1" : "bg-color-3 text-white")} id="try">
		<div className={"container" + " " + (high && "py-6")}>
      <ShortHookForm />
		</div>
	</div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyShortForm on PrismicHomepageDataBodyShortForm {
    primary {
      high
    }
  }
  fragment PageDataBodyShortForm on PrismicPageDataBodyShortForm {
    primary {
      high
    }
  }
`
