import * as React from 'react'
import { PrismicRichText } from '@prismicio/react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

export const Pricing = ({ items, isSwitchOn }) =>  {
  const pricing = items.map((item, index) => {

		const openLink = () => {
			if (item.btn_link.url.includes("buy.stripe.com")) {
				var visitor_id
  			var _paq = window._paq || [];
				_paq.push([ function() { visitor_id = this.getVisitorId(); }]);
				const button_link = item.btn_link.url + "?client_reference_id=" + visitor_id
				window.open(button_link, '_blank', 'noopener,noreferrer')
			} else {
				window.open(item.btn_link.url, '_blank', 'noopener,noreferrer')
			}
		}
		

    return (
      <div key={index} className={"col-12 col-lg-4 border overflow-hidden" + " " + ((index == 0) ? "rounded-start-4" : "") + " " + ((index+1 == items.length) ? "rounded-end-4" : "") + " " + ((index+1 != items.length) ? "no-border-end" : "")}>
				<div className={"d-flex flex-column px-3 h-100 rounded-0" + " " + (((item.subscription && !isSwitchOn) || (!item.subscription && isSwitchOn)) ? "bg-color-1" : "bg-white")}>
					<div className="card-body d-flex flex-column align-content-between py-4">
						<div className={((item.subscription && !isSwitchOn) || (!item.subscription && isSwitchOn)) ? "" : "opacity-50"}>
							<h5 className="card-title text-uppercase text-center">{item.title}</h5>
							<hr/>
							<PrismicRichText field={item.main_features.richText} />
							<ul className="fa-ul">			
								{item.extra_feature_1 &&
								<li className="fw-bold"><span className="fa-li"><i className="fas fa-check icon-main"></i></span>{item.extra_feature_1}
									<OverlayTrigger
        					  trigger="click"
        					  overlay={
        					    <Popover id={`popover-positioned`}>
        					      <Popover.Header as="h3">{item.extra_feature_1_popover_label}</Popover.Header>
        					      <Popover.Body>{item.extra_feature_1_popover_text}</Popover.Body>
        					    </Popover>
        					  }
        					>
        					  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="ms-1 bi bi-question-circle-fill" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/></svg>
        					</OverlayTrigger>
									</li>
								}
								{item.extra_feature_2 &&
								<li className="fw-bold"><span className="fa-li"><i className="fas fa-check icon-main"></i></span>{item.extra_feature_2}
									<OverlayTrigger
        					  trigger="click"
        					  overlay={
        					    <Popover id={`popover-positioned`}>
        					      <Popover.Header as="h3">{item.extra_feature_2_popover_label}</Popover.Header>
        					      <Popover.Body>{item.extra_feature_2_popover_text}</Popover.Body>
        					    </Popover>
        					  }
        					>
        					  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="ms-1 bi bi-question-circle-fill" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/></svg>
        					</OverlayTrigger>
									</li>
									}
							</ul>
						</div>
						<div className="mt-3 h-100 d-flex flex-column justify-content-end">
							<div className={((item.subscription && !isSwitchOn) || (!item.subscription && isSwitchOn)) ? "" : "opacity-50"}>
								<div className="text-center fs-0875 opacity-75 my-2 px-3">
									<PrismicRichText field={item.text_bottom.richText} />
								</div>
							</div>
							<a onClick={openLink} className={"btn btn-block text-uppercase" + " " + (((item.subscription && !isSwitchOn) || (!item.subscription && isSwitchOn)) ? "btn-primary" : "btn-dark")}>{item.btn_text}</a>
								<div className={"d-flex flex-column justify-content-end" + " " + (((item.subscription && !isSwitchOn) || (!item.subscription && isSwitchOn)) ? "" : "opacity-50")} style={{height:"32px"}}>
									<p id={"price_text_" + index} className="text-center fs-0875 mb-0">{item.text_after_btn}</p>
								</div>
						</div>
					</div>
				</div>
			</div>
    )
  })

  return (
		<div className="mt-3 overflow-hidden">
			<div className="row gx-0 gy-4">	
    	  {pricing}
      </div>
      {/*<div className="mt-4 mt-lg-0 d-grid gap-2">
					<a href={trial_btn_link.url} target="_blank" className={"btn btn-warning text-uppercase"} style={{borderRadius:"0px",padding:"1.5rem 3rem"}}>{"START FREE TRIAL"}</a>
			</div>*/}
		</div>
    )
}