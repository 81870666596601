import * as React from 'react'
import { graphql } from 'gatsby'
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';

export const LangsHeader = ({ slice }) => {

  const handleChange = (event) => {
    const selectedLabel = event.target.value;
    const selectedItem = slice.items.find(item => item.label === selectedLabel);
    if (selectedItem && selectedItem.link && selectedItem.link.url) {
      window.location.href = selectedItem.link.url;
    }
  };

  const renderedMenuLinks = slice.items.map((item, index) => {
    return (
      <li key={index} className="nav-item">
        <a className="nav-link" href={item.link.url}>{item.label}</a>
      </li>
    )
  })

  return (
    <Navbar collapseOnSelect expand="lg" bg={"white"} variant={"light"} className={"w-100 border-bottom"}>
			<div className="container-fluid py-2 px-3 px-lg-5">
				<a className="navbar-brand" href="/">
					<img src={"/yohn-logo.png"} height="36" width="115" className="d-inline-block align-top" alt="Yohn logo"/>
				</a>
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          <ul className="navbar-nav ms-auto">
            {renderedMenuLinks}
          </ul>
        </Navbar.Collapse>
			</div>
    </Navbar>
  )
}

export const query = graphql`
  fragment PageDataBodyLangsHeader on PrismicPageDataBodyLangsHeader {
    items {
      label
      link {
        url
      }
    }
  }
`
