import * as React from 'react'
import { useState } from 'react';
import { graphql } from 'gatsby'
import { useForm } from "react-hook-form";
import {callHook} from '../utils/utils';
var validator = require("email-validator");
import settings from '../settings.json';
import sha1 from 'crypto-js/sha1';
import Base64 from 'crypto-js/enc-base64';
import Hex from 'crypto-js/enc-hex';

export const ContactForm3 = ({ slice, index }) => {
  const {title, subtitle} = slice.primary

  const [isSend, setSend] = useState(false);
  const [isError, setError] = useState(false);

  const { register, setValue, handleSubmit, watch, formState: { errors, isSubmitted } } = useForm({
    mode: "onChange"
  });
  
  const isFirst = index === 0;

  if (typeof window !== 'undefined'){
    const pageUrl = window.location;
    //console.log('pageUrl: ', pageUrl);
  }  

  const onSubmit = async (data) => {
    // data = Object.assign({id: "yohn-contact-us"}, data);
    // console.log('data: ', data);
    const hashDigest = sha1(data.email);
    const emailSHA1 = Hex.stringify(hashDigest);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({'SHA1_USER_EMAIL': emailSHA1});

    var visitorId
  	var _paq = window._paq || [];
		_paq.push([ function() { visitorId = this.getVisitorId(); }]);
    data.visitorId = visitorId

    const url = settings.forms_webhook;
    const r = await callHook({url, uid:'yohn-contact-us', params:data});
    //console.log(r);
    // if (r && r.success === true) {
    //   setError(false);
    // } else {
    //   setError(true);
    // }
    // setValue("full", "");
    setSend(true);
  }

  if (isSend) {
    return (
      <div className="py-6 bg-color-1">
			  <div className="container">
          <div className="text-center col-12 col-lg-8 mx-auto">
            {!isError && (
            <>
              <h3>Thank You!</h3>
              <p>We will contact you shortly.</p>
            </>
            )}
            {isError && (
            <>
              <h3>An error has occurred</h3>
              <p>Please try again later, server is not available now.</p>
            </>
            )}
            <div className="col-auto">
              <button onClick={()=>setSend(false)} className={"btn btn-sm text-uppercase btn-primary"}>{isError ? "Try again" : "Ask another question"}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="py-6">
			<div className="container">
        <div className="row g-5">
          <div className="col-12 col-lg-5 mx-auto">
            {isFirst ? <h1 className="h2 mb-3">{title}</h1> : <h2 className="mb-3">{title}</h2>}
            <h3 className="">{subtitle}</h3>
          </div>
          <div className="col-12 col-lg-7 mx-auto">
            <form id="yohn-contact-us" className="text-center" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <input
                  id="email"
                  placeholder="Your email address"
                  className={'form-control' + " " + (errors.email && "is-invalid")}
                  {...register('email', {required: true, validate: (input) => validator.validate(input)})}
                />
                <div className="invalid-feedback" wfd-invisible="true">{'Invalid email address'}</div>
              </div>
              <div className={"mt-4"}>
                <input
                  id="phone"
                  placeholder="+0 (000) 000-0000"
                  type="tel"
                  className={"form-control"}
                  {...register('phone')}
                />
              </div>
              <div className={"mt-4"}>
                <textarea
                  id="text"
                  placeholder="Type your question"
                  type="text"
                  rows="5"
                  className={"form-control"}
                  {...register('text', {required: true})}
                />
                <div className="invalid-feedback" wfd-invisible="true">{'Required!'}</div>
              </div>
              <input type="submit" value="Submit" className={"mt-4 btn btn-sm btn-primary text-uppercase w-100"}/>
            </form>
          </div>
        </div>
			</div>
		</div>
  )
}

export const query = graphql`
  fragment PageDataBodyContactForm3 on PrismicPageDataBodyContactForm3 {
    primary {
      title
      subtitle
    }
  }
`
