import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Features2 = ({ slice }) => {
  const {title} = slice.primary

  const features = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-6 col-lg-4">
        <div  className="bg-light rounded-4 h-100 p-4 d-flex flex-column justify-content-start align-items-center">
          <div className="rounded-3 d-flex justify-content-center align-items-center mb-3 bg-dark" style={{height:"64px",width:"64px"}}>
            <img src={item.img.url} className="img-fluid icon-primary-color" alt={item.img.alt} height="40" width="40"/>
          </div>
				  <h4 className="mb-0 text-center">{item.title}</h4>
        </div>
			</div>
    )
  })

  return (
    <div  className="my-6" id="features">
			<div className="container">
        <h2 className="text-center mb-5">{title}</h2>
				<div className="row g-2 g-md-4">
          {features}
				</div>
			</div>
		</div>
  )
}

export const query = graphql`
  fragment PageDataBodyFeatures2 on PrismicPageDataBodyFeatures2 {
    primary {
      title
    }
    items {
      title
      img {
        url
        alt
      }
    }
  }
`
