import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import Carousel from 'react-bootstrap/Carousel';

export const HeroSlider = ({ slice }) => {
  const {btn_label, btn_link} = slice.primary

  const slides = slice.items.map((item, index) => {
    return (
      <Carousel.Item>
        <div className="container">
          <div key={index} className="h-100">
            <div className="row g-5">
              <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                {(index == 0) &&
                  <h1 className="text-center text-md-start">{item.title}</h1>
                }
                {(index > 0) &&
                  <h2 className="h1 text-center text-md-start">{item.title}</h2>
                }
                <div className="opacity-75 mt-4 text-center text-md-start rt-ul-md-center">
                  <PrismicRichText field={item.text.richText}/>
		            </div>
                <div className="mt-4 text-center text-md-start">
		      	      <a href={btn_link.url} className={"btn text-uppercase btn-primary"}>{btn_label}</a>
                </div>
              </div>
              <div className="col-12 col-md-6 d-none d-md-flex flex-column justify-content-center align-items-center">
                <img src={item.img.url} alt={item.img.alt} className="img-fluid rounded-4 img-shadow" style={{maxHeight:"600px"}} />
              </div>
            </div>
		      </div>
        </div>
      </Carousel.Item>
    )
  })

  return (
    <>
      <div id="top" className="bg-light py-6 d-flex flex-column justify-content-center" style={{minHeight:"600px"}}>
        <Carousel indicators={false} data-bs-theme="dark">
          {slides}
        </Carousel>
		  </div>
    </>
  )
}

export const query = graphql`
  fragment PageDataBodyHeroSlider on PrismicPageDataBodyHeroSlider {
    primary {
      btn_label
      btn_link {
        url
      }
    }
    items {
      title
      text {
        richText
      }
      img {
        url
        alt
      }
    }
  }
`
