import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const TreesCalc = ({ slice }) => {
  const {title, text} = slice.primary

	const [employeesAmount, setEmployeesAmount] = useState(50);
	const [trees, setTrees] = useState(1);

	const [prinringCost, setPrinringCost] = useState(65);
	const [money, setMoney] = useState(0);
	
	
	const onEmployeesAmountChange = (event) => {
		const newAmount = event.target.value;
		setEmployeesAmount(newAmount);
    //console.log("event.target.value",newAmount)
		//console.log("employeesAmount",employeesAmount)
		calc({amount: newAmount, cost:prinringCost});
		
  };

	const onPrinringCostChange = (event) => {
		const newCost = event.target.value;
    setPrinringCost(newCost);
		calc({amount:employeesAmount, cost:newCost});
		//console.log(prinringCost)
  };

	const calc = ({amount, cost}) => {
	  setTrees(Math.round(amount*0.028));
		setMoney(amount*(cost-65));
	};


  return (
		<div className="pricing mt-5">
			<div className="container">
				<div className="row align-items-start">
					<div className="col-12 col-lg-5">
						<h3 className="mb-3">{title}</h3>
						<PrismicRichText field={text.richText} />
					</div>
					<div className="col-12 col-lg-7">
						<div className="bg-light rounded-4 p-4">
							<h5 className="mb-0">Amount of employees</h5>
							<div className="row d-flex align-items-center">
								<div className="col">
									<input type="range" id="employeesAmount" name="employeesAmount" defaultValue="50" min="50" max="1000" className="form-range w-100" onChange={onEmployeesAmountChange}/>
								</div>
								<div className="col-auto">
									<div className="bg-white rounded-3 p-3" style={{width:"88px"}}>
										<p className="h5 text-center mb-0">{employeesAmount}</p>
									</div>
								</div>	
							</div>
							<h5 className="mt-3 mb-0">Printing cost</h5>
							<div className="row d-flex align-items-center">
								<div className="col">
									<input type="range" id="employeesAmount" name="employeesAmount" defaultValue="65" min="65" max="300" className="form-range w-100" onChange={onPrinringCostChange}/>
								</div>
								<div className="col-auto">
									<div className="bg-white rounded-3 p-3" style={{width:"88px"}}>
										<p className="h5 text-center mb-0">{`${prinringCost} €`}</p>
									</div>
								</div>	
							</div>
							<div className="row">
								<div className="col">
								<div className="bg-white rounded-3 p-4 mt-3">
									<div className="d-flex flex-column justify-content-between align-items-center">
										<div className="bg-color-1 rounded-3 p-3">
											<img src={"/icons/tree.svg"} alt={"Tree icon"} height="32" width="32"/>
										</div>	
										<div className="text-center mt-3">
											<p className="h5">Bottom Line</p>
											<p className="h2">{`${money} €`}</p>
											<p className="opacity-50 mb-0" style={{fontSize:".75rem"}}>savings per year</p>
										</div>
									</div>
								</div>
								</div>
								<div className="col">
									<div className="bg-white rounded-3 p-4 mt-3">
										<div className="d-flex flex-column justify-content-between align-items-center">
											<div className="bg-color-1 rounded-3 p-3">
												<img src={"/icons/tree.svg"} alt={"Tree icon"} height="32" width="32"/>
											</div>	
											<div className="text-center mt-3">
												<p className="h5">Trees Saved</p>
												<p className="h2">{trees}</p>
												<p className="opacity-50 mb-0" style={{fontSize:".75rem"}}>eco-friendly impact per year</p>
											</div>
										</div>
									</div>
								</div>	
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
  )
}

export const query = graphql`
  fragment BlogItemDataBodyTreesCalc on PrismicBlogItemDataBodyTreesCalc {
		slice_type
    primary {
      title
			text {
				richText
			}
    }
  }
`
