const BLACK_LIST = [
  'iplogger.com',
  'www.iplogger.com',
  'bit.ly',
  'www.bit.ly',
  'www.gg.gg',
  'gg.gg'
]
// bitbucket/colibri/our_stacks/yo.hn/mails/colibri-sl-email/handler.js
function checkFull(full) {
  try {
    const url = new URL(full);
    const {protocol, port} = url;
    // console.log(`    check: "${protocol}" and port: "${port}"`);
    if (!protocol.startsWith('http')) return false;
    if (port) return false;
    const hostname = url.hostname.trim();
    // console.log(`   check hostname: "${hostname}"`);
    if (!hostname) return false;
    if (!hostname.includes('.')) return false;
    // if (WhiteList.includes(hostname)) return true;
    // if (BlackList.includes(hostname)) return false;
    // todo: check by google
    // const checkUrl = new URL(`${protocol}//${hostname}`).href;
    // console.log(`check by ggogle: "${checkUrl}"`);
    // const r = await getSafeUrl(checkUrl);
    // // console.log('r: ', JSON.stringify(r));
    // if (r.matches) {
    //   console.log(' > FAIL: ', r.matches);
    //   return false;
    // }
    return true;
  } catch (error) {
    // console.log(error); // => TypeError, "Failed to construct URL: Invalid URL"
    return false;
  }
};
const normalizeUrl = (chekUrl) => {
  if (!chekUrl) return;
  // https://en.wikipedia.org/wiki/List_of_URI_schemes
  const SCHEMES = ['mailto:','tel:','ftp:','http:','https:','smsto:']
  const SCHEMES_EXT = ['mailto:','tel:','smsto:']
  try {
    const url = chekUrl.includes(':') ? new URL(chekUrl) : new URL(`https://${chekUrl}`);
    // console.log('check url: ', url);
    const {protocol, port, hostname, pathname, search, hash} = url;
    
    if (SCHEMES_EXT.includes(protocol)) return url;
    if (!hostname) return;
    if (!hostname.includes('.')) return;
    if (hostname.length < 4) return;
    if (BLACK_LIST.includes(hostname)) return;
    const rootDomain = hostname.split('.').pop();
    if (rootDomain.length<2) return;

    const normUrl = new URL(`${protocol}//${hostname}`);
    normUrl.pathname = pathname;
    normUrl.search = search;
    normUrl.hash = hash;

    if (!SCHEMES.includes(protocol)) {
      // console.log('wrong protocol: ', protocol);
      return;
    }
    return normUrl.href;
  } catch(e) {
    // console.error('e: ', e);
  }
};
export { normalizeUrl, checkFull };
// module.export = { normalizeUrl, checkFull };
