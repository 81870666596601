import * as React from 'react'
import {useEffect} from 'react';
import { graphql } from 'gatsby'
import Accordion from 'react-bootstrap/Accordion';
import { PrismicRichText } from '@prismicio/react'

export const CardInAction = ({ slice }) => {

  const buttons = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-auto">
		  	<a href={item.btn_link_anchor} className={"btn text-uppercase" + " " + (item.primary ? "btn-primary" : "btn-dark")}>{item.btn_label}</a>
		  </div>
    )
  })

  useEffect(() => {
    const animatedCard = document.getElementById('animated-card');
    const animatedCardImg = document.getElementById('animated-card-img');
    const phone = document.getElementById('phone');
    const phoneCard = document.getElementById('phone-card');
    const text2 = document.getElementById('text-2');
    //console.log(animatedCard);
    //console.log(animatedCard.id);

    /*CARD*/
    document.addEventListener('scroll', function () {
      const scrollPercent = (document.documentElement.scrollTop + document.body.scrollTop) / 
            (document.documentElement.scrollHeight - document.documentElement.clientHeight) * 100;
      //console.log(scrollPercent);
      const startMovingPoint = 9;
      const leftMove = 19;
      const rightMove = 13.5;
      if (matchMedia('only screen and (min-width: 992px)').matches) {
        if (scrollPercent > startMovingPoint && scrollPercent < leftMove) {
          //animatedCardImg.style.transformStyle = `preserve-3d`;
          //animatedCardImg.style.transform = `perspective(${1000 - (65 * (scrollPercent - startMovingPoint))}px) rotateY(-${.5 * (scrollPercent - startMovingPoint)}deg)`;
          animatedCard.style.transform = `translate3d(${(scrollPercent - startMovingPoint)*24}px, 0, 0) scale3d(${1-((scrollPercent - startMovingPoint)/80)}, ${1-((scrollPercent - startMovingPoint)/80)}, ${1-((scrollPercent - startMovingPoint)/80)})`;
          return;
        } else if (scrollPercent >= 28) {
          animatedCardImg.style.transform = `translateY(${-(scrollPercent-28)*100}px)`;
          return;
        } if (scrollPercent >= leftMove /*&& scrollPercent < rightMove*/) {
          //animatedCardImg.style.transform = `perspective(${(75 * (scrollPercent - startMovingPoint))}px) rotateY(${-15.5 + (8 * (scrollPercent - 11))}deg)`;
          return;
        } else if (scrollPercent >= rightMove) {
          //animatedCardImg.style.transform = `perspective(${700 - (65 * (scrollPercent - 12))}px) rotateY(${10 - (4.5 * (scrollPercent - 12))}deg)`;
          return;
        } else {
          animatedCard.style.transform = `initial`;
          animatedCardImg.style.transform = `initial`;
          phone.style.opacity = `0`;
          text2.style.transform = `initial`;
          text2.style.opacity = `1`;
          
          return;
        }
      }
    });

    /*BLOCK 2*/
    document.addEventListener('scroll', function () {
      const scrollPercent = (document.documentElement.scrollTop + document.body.scrollTop) / 
            (document.documentElement.scrollHeight - document.documentElement.clientHeight) * 100;
  
      if ( scrollPercent >= 8 && scrollPercent < 28) {
        phone.style.opacity = `${(scrollPercent-8)/5}`;
        phoneCard.style.opacity = `${(scrollPercent-15)}`;
        phoneCard.style.transform = `scale3d(${Math.max(Math.min((scrollPercent-15),1),0)}, ${Math.max(Math.min((scrollPercent-15),1),0)}, ${Math.max(Math.min((scrollPercent-15),1),0)})`;
        text2.style.opacity = `1`;
        return;
      } else if (scrollPercent >= 28) {
        text2.style.transform = `translateY(${-(scrollPercent-28)*50}px)`;
        text2.style.opacity = `${1-(scrollPercent-28)}`;
        return;
      } else {
        //phone.style.opacity = `0`;
        text2.style.transform = `initial`;
        text2.style.opacity = `1`;
        return;
      }
    });
  }, []);

  const {title, text, title2, text2, title3, img, phone, phone_card, btn_label, btn_anchor} = slice.primary

  return (
    <div className={"bg-light py-6 "}>
      <div className="container-xxl position-relative overflow-visible" style={{}}>
        <div className="row mb-4 mb-lg-5">
          <div className="col-12 col-lg-8 mx-auto text-center">
            <h1 className="mb-4 mb-lg-5">{title}</h1>
            <div className="opacity-75">
              <PrismicRichText field={text.richText} />
            </div>
          </div>
        </div>
        <div id="animated-card" className="d-flex justify-content-center sticky-lg-top px-4 px-lg-0" style={{top: "80px"/*,zIndex: "2000"*/, willChange: "opacity, transform", opacity: "1", transform: "translate3d(0vw, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", transformStyle: "preserve-3d"}}>
          <div className="d-flex justify-content-center" style={{width:"280px"}}>
            <img id="animated-card-img" src={img.url} alt={img.alt} className="animated-card  sticky-top" style={{transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", opacity: "1", transformStyle: "preserve-3d", perspective: "500px"}}/>
          </div>
        </div>
        <div className="row" style={{}}>
          <div className="col-12 col-lg-6">
            <div id="text-2" className="d-none d-lg-block sticky-top d-flex flex-column justify-content-center" style={{top:"80px", marginTop:"240px"}}>
              <h2 className="h1 mb-5">{title2}</h2>
              <div className="opacity-75">
                <PrismicRichText field={text2.richText} />
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start card-block-3">
              <h2 className="h1 mb-4 mb-lg-5">{title3}</h2>
              <div className="d-flex row g-3 mt-0" style={{zIndex:"9999"}}>
		  			    {buttons}
		  		    </div>
            </div>
          </div>
          <div className="d-none d-lg-block col-6 text-end" >
            <div className="sticky-top" style={{top:"160px"}}>
              <img id="phone" src={phone.url} alt={phone.alt} height="624" width="308" style={{opacity:"0"}}/>
              <img id="phone-card" src={phone_card.url} alt={phone_card.alt} height="624" width="308" className="position-absolute img-shadow" style={{opacity:"0",right:"0px",transformOrigin:"bottom"}}/>
            </div>
          </div>       
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyCardInAction on PrismicPageDataBodyCardInAction {
    primary {
      title
      text {
        richText
      }
      title2
      text2 {
        richText
      }
      title3
      img {
        url
        alt
      }
      phone {
        url
        alt
      }
      phone_card {
        url
        alt
      }
    }
    items {
      btn_label
      btn_link_anchor
      primary
    }
  }
`
