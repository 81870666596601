import * as React from 'react'
import { graphql } from 'gatsby'
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

export const SeparateHeader = ({ slice }) => {
  const {transparent} = slice.primary

  const links = slice.items.map((item, index) => {
    return (
      <li key={index} className="nav-item">
				<a className="nav-link" href={item.anchor ? "#"+item.anchor : item.link.url}>{item.label}</a>
			</li>
    )
  })

  return (
    <Navbar collapseOnSelect expand="lg" bg={transparent ? "transparent" :"white"} variant={transparent ? "dark" : "light"} className={"w-100" + " " + (transparent ? "navbar-dark position-absolute" : "border-bottom")} style={{top:"0px", left:"0px"}}>
			<div className="container-fluid py-2 px-3 px-lg-5">
				<a className="navbar-brand" href="/">
					<img src={(transparent ? "/yohn-logo-light.png" : "/yohn-logo.png")} height="36" width="115" className="d-inline-block align-top" alt="Yohn logo"/>
				</a>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <ul className="navbar-nav ms-auto">
            {/*links*/}
            <li>
              <NavDropdown
                id="nav-qr-dropdown"
                title="QR Code Generator"
                menuVariant="light"
              >
                <NavDropdown.Item href="/free-link-to-qr-code-converter/">Free</NavDropdown.Item>
                <NavDropdown.Item href="/qr/">Premium</NavDropdown.Item>
              </NavDropdown>
            </li>
            <li className="nav-item">
			      	<a className="nav-link" href={"/branded-url-shortener/"}>{"URL shortener"}</a>
			      </li>
            {/*<li>
              <NavDropdown
                id="nav-cards-dropdown"
                title="Digital business cards"
                menuVariant="light"
              >
                <NavDropdown.Item href="/">For Individuals</NavDropdown.Item>
                <NavDropdown.Item href="/">For Companies</NavDropdown.Item>
              </NavDropdown>
            </li>*/}
            <li className="nav-item">
			      	<a className="nav-link" href={"/digital-business-cards/"}>{"Digital business cards"}</a>
			      </li>
            <li className="nav-item">
			      	<a className="nav-link" href={"/#pricing"}>{"Pricing"}</a>
			      </li>
            <li className="nav-item mt-3 mt-lg-0 nav-link p-0">
							<a className="btn btn-sm btn-light text-uppercase" href="/login/">Login</a>
						</li>
            <li className="nav-item mt-3 mt-lg-0">
							<a className="btn btn-sm btn-primary text-uppercase lightbox-222062268194051" href="/contact-us/">Contact Us</a>
						</li>
          </ul>
        </Navbar.Collapse>
			</div>
    </Navbar>
  )
}

export const query = graphql`
  fragment HomepageDataBodySeparateHeader on PrismicHomepageDataBodySeparateHeader {
    primary {
      transparent
    }
    items {
      label
      anchor
      link {
        url
      }
    }
  }
  fragment PageDataBodySeparateHeader on PrismicPageDataBodySeparateHeader {
    primary {
      transparent
    }
    items {
      label
      anchor
      link {
        url
      }
    }
  }
  fragment BlogDataBodySeparateHeader on PrismicBlogDataBodySeparateHeader {
    slice_type
    primary {
      transparent
    }
    items {
      label
      anchor
      link {
        url
      }
    }
  }
  fragment BlogItemDataBodySeparateHeader on PrismicBlogItemDataBodySeparateHeader {
    slice_type
    primary {
      transparent
    }
    items {
      label
      anchor
      link {
        url
      }
    }
  }
`
