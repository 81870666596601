import * as React from 'react'
import { graphql } from 'gatsby'

export const AdditionalFooterInfo = ({ slice }) => {
  const {text} = slice.primary

  return (
    <div className="footer bg-footer text-center text-white pt-5">
			<h5 className="mb-0">{text.text}</h5>
		</div>
  )
}

export const query = graphql`
  fragment PageDataBodyAdditionalFooterInfo on PrismicPageDataBodyAdditionalFooterInfo {
    primary {
      text {
				richText
				text
			}
    }
  }
`
