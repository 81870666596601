import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const MainFeatures = ({ slice, index }) => {
  const {title, btn_text, btn_link} = slice.primary

  const isFirst = index === 0;

  const features = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-sm-6 col-lg-3 d-flex flex-column align-items-center text-center px-4 mb-5 mb-lg-0">
				<img src={item.icon.url} alt={item.icon.alt} className="img-fluid mb-4" height="80" width="80" loading="lazy"/>
				<h5>{item.feature}</h5>
			</div>
    )
  })

  return (
    <div  className={"py-6"} id="features">
			<div className="container text-center">
				{isFirst ? <h1 className="h2">{title}</h1> : <h2 className="">{title}</h2>}
				<div className="row mt-5">
          {features}
				</div>
        <a href={btn_link.url} target="_blank" className={"mt-5 btn btn-block text-uppercase btn-primary"}>{btn_text}</a>
			</div>
		</div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyMainFeatures on PrismicHomepageDataBodyMainFeatures {
    primary {
      title
      btn_text
      btn_link {
        url
      }
    }
    items {
      feature
      icon {
        url
        alt
      }
    }
  }
  fragment PageDataBodyMainFeatures on PrismicPageDataBodyMainFeatures {
    primary {
      title
      btn_text
      btn_link {
        url
      }
    }
    items {
      feature
      icon {
        url
        alt
      }
    }
  }
`
