
export async function getFromApi({token, url, params={}, method='GET'}) {
  let response = null;
  try {
    const options = {
      method, // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow',
      // credentials: 'include',
      headers: {
        // "Access-Control-Allow-Origin": "*", // This had to be removed to fix the CORS error
        "content-type": "application/json",
      },
    };
    if (token) {
      const bearer = `Bearer ${token}`;
      options.headers['Authorization'] = bearer;
      options.headers["Access-Control-Allow-Credentials"] = true;
    }
    if ( 'GET' === method ) {
      url += '?' + ( new URLSearchParams( params ) ).toString();
      //console.log('url: ', url);
    } else {
      //console.log(`send to: ${url}`);
      options.body = JSON.stringify( params );
    }
    response = await fetch(url, options);
    // console.log(response);
    //console.log('status code: ', response.status); // 👉️ 200

    if (!response.ok) {
      //console.log(response);
      throw new Error(`Error! status: ${response.status}`);
    }
    const json = await response.json();
    json.success = true;
    // console.log('received response: ', json);
    return json;
  } catch (e) {
    // interface Error {
    //   name: string;
    //   message: string;
    //   stack?: string;
    // }
    const status = response ? response.status : null;
    //console.log(e);
    const message = e.message || 'Error';
    const r = {success: false, message, status}
    return r;
  }
}

export async function callHook({uid, url, params={}, mode}) {
  let response = null;
  try {
    const options = {
      method: 'POST',
      mode: "cors", // no-cors, *cors, same-origin
      redirect: 'follow',
      headers: { "content-type": "application/json" },
    };
    //console.log(`send to: ${url}`);

    // const data = JSON.stringify(params);

    options.body = JSON.stringify({uid, data:params});
    response = await fetch(url, options);
    // console.log(response);
    //console.log('status code: ', response.status); // 👉️ 200

    if (!response.ok) {
      //console.log(response);
      throw new Error(`Error! status: ${response.status}`);
    }
    const json = await response.json();
    return json;
  } catch (e) {
    // interface Error {
    //   name: string;
    //   message: string;
    //   stack?: string;
    // }
    const status = response ? response.status : null;
    //console.log(e);
    const message = e.message || 'Error';
    const r = {success: false, message, status}
    return r;
  }
}

export const Replace = (sTemplate, mData) => {
  if (!mData) return sTemplate;
  if (typeof sTemplate === 'string') {
    // mData = mData instanceof Object ? mData : {};
    mData = mData ? mData : {};
    return sTemplate.replace(
      /\$\{\s*([$#@\-\d\w]+)\s*\}/gim,
      function (fullMath, grp) {
        let val = mData[grp];
        if (typeof val === 'function') {
          val = val();
        } else if (val === null || val === undefined) {
          val = '';
        } else if (typeof val === 'object' || typeof val === 'symbol') {
          val = val.toString();
        } else {
          val = val.valueOf();
        }
        return val;
      },
    );
  }
  return '';
};