import * as React from 'react'
import { graphql } from 'gatsby'
import JotFormReact from 'jotform-react';

export const PurchaseForm = ({ slice }) => {
  const {title, form_url} = slice.primary

  return (
    <div className="py-6" id="purchase">
			<div className="container">
				<h2 className="text-center mb-5">{title}</h2>
				<JotFormReact formURL={form_url} initialHeight={464} autoResize={false}/>
			</div>
		</div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyPurchaseForm on PrismicHomepageDataBodyPurchaseForm {
    primary {
      title
      form_url
    }
  }
  fragment PageDataBodyPurchaseForm on PrismicPageDataBodyPurchaseForm {
    primary {
      title
      form_url
    }
  }
`
