import * as React from 'react'
import { useState } from 'react';
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { useForm } from "react-hook-form";
import {callHook} from '../utils/utils';
var validator = require("email-validator");
import settings from '../settings.json';

export const ReportUrlForm = ({ slice, index }) => {
  const {title, text} = slice.primary

  const [isSend, setSend] = useState(false);
  const [isError, setError] = useState(false);

  const { register, setValue, handleSubmit, watch, formState: { errors, isSubmitted } } = useForm({
    mode: "onChange"
  });

  const isFirst = index === 0;

  const onSubmit = async (data) => {
    //console.log('data: ', data);
    const url = settings.forms_webhook;
    const r = await callHook({url, uid:'yohn-report-url', params:data});
    //console.log(r);
    // if (r && r.success === true) {
    //   setError(false);
    // } else {
    //   setError(true);
    // }
    // setValue("full", "");
    setSend(true);
  }

  if (isSend) {
    return (
      <div className="py-6 bg-color-1">
			  <div className="container">
          <div className="text-center col-12 col-lg-8 mx-auto">
            {!isError && (
            <>
              <h3>Thank You!</h3>
              <p>We will check this link soon.</p>
            </>
            )}
            {isError && (
            <>
              <h3>An error has occurred</h3>
              <p>Please try again later, server is not available now.</p>
            </>
            )}
            <div className="col-auto">
              <button onClick={()=>setSend(false)} className={"btn btn-sm text-uppercase btn-primary"}>{isError ? "Try again" : "Send another report"}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="py-6 bg-color-1">
			<div className="container">
        <div className="col-12 col-md-9 col-lg-7 mx-auto">
          <div className="text-center">
          {isFirst ? <h1 className="h2 mb-4">{title}</h1> : <h2 className="mb-4">{title}</h2>}
            <PrismicRichText field={text.richText} />
          </div>
          <div className="mt-5">
            <form id="yohn-report-url" className="text-center" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <input
                  id="url"
                  placeholder="e.g https://yo.hn/...."
                  className={"form-control"}
                  {...register('url', {required: true})}
                />
                <div className="invalid-feedback" wfd-invisible="true">{'Invalid email address'}</div>
              </div>
              <div className={"mt-4"}>
                <textarea
                  id="text"
                  placeholder="Message..."
                  type="text"
                  rows="5"
                  className={"form-control"}
                  {...register('text', {required: true})}
                />
                <div className="invalid-feedback" wfd-invisible="true">{'Required!'}</div>
              </div>
              <input type="submit" value="Send Report" className={"mt-4 btn btn-sm btn-primary text-uppercase"}/>
            </form>
          </div>
        </div>
			</div>
		</div>
  )
}

export const query = graphql`
  fragment PageDataBodyReportUrlForm on PrismicPageDataBodyReportUrlForm {
    primary {
      title
      text {
        richText
      }
    }
  }
`
