import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const ImageAndText = ({ slice, index }) => {
  const {title, text, img} = slice.primary

  const isFirst = index === 0;

  return (
    <div className={"my-6"}>
      <div className="container">
        <div className="bg-light p-5 rounded-4 img-shadow">
          <div className="row">
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
              {isFirst ? <h1 className="h2">{title}</h1> : (title && <h2 className="">{title}</h2>)}
              <div className="opacity-75 mt-4">
                <PrismicRichText field={text.richText} />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <img src={img.url} alt={img.alt} className="img-fluid rounded-4 " style={{maxHeight:"600px"}} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyImageAndText on PrismicPageDataBodyImageAndText {
    primary {
      title
      text {
        richText
      }
      img {
        url
      }
    }
  }
`
