import * as React from 'react'
import { graphql } from 'gatsby'
import JotFormReact from 'jotform-react';

export const BlogPurchaseForm = ({ slice }) => {
  const {title, form_url} = slice.primary

  return (
    <div className="">
			<JotFormReact formURL={form_url} autoResize={true}/>
		</div>
  )
}

export const query = graphql`
  fragment BlogItemDataBodyBlogPurchaseForm on PrismicBlogItemDataBodyBlogPurchaseForm {
    slice_type
    primary {
      title
      form_url
    }
  }
`
