import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const TableFourCols = ({ slice }) => {
  const tableRows = slice.items.map((item, index) => {
    return (
      <tr key={index}>
        <th>
            <PrismicRichText field={item.col_1.richText} />
        </th>
        <th>
          <PrismicRichText field={item.col_2.richText} />
        </th>
        <th>
          <PrismicRichText field={item.col_3.richText} />
        </th>
        <th>
          <PrismicRichText field={item.col_4.richText} />
        </th>
      </tr>
    )
  })

  return (
    <div className="container">
      <table className="table table-light table-bordered">
        <tbody>
          {tableRows}
        </tbody>
      </table>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyTableFourCols on PrismicPageDataBodyTableFourCols {
    items {
      col_1 {
        richText
      }
      col_2 {
        richText
      }
      col_3 {
        richText
      }
      col_4 {
        richText
      }
    }
  }
`
