import * as React from 'react'
import { graphql } from 'gatsby'
import { useState } from 'react';
import { useForm } from "react-hook-form";
import {callHook} from '../utils/utils';

export const LostAndFound = ({ slice, index }) => {
  const [isSend, setSend] = useState(false);
  const [isError, setError] = useState(false);
  const [lostId, setLostId] = React.useState();

  const { register, setValue, handleSubmit, watch, formState: { errors, isSubmitted } } = useForm({
    mode: "onChange"
  });

  const {title, claim_tag} = slice.primary
  const isFirst = index === 0;


  React.useEffect(() => {
    console.log('did mount');
    if (typeof window !== 'undefined'){
      const params = new URLSearchParams(window.location.search.substring(1));
      let _lostId = params.get("id");
      console.log("id", _lostId)
      setLostId(_lostId);
    } 
  }, []);

  const onSubmit = async (data) => {
    console.log('data: ', data);
    let url = "https://n8n.yohn.io/webhook/lost-and-found";
    let mode = "no-cors";

    data.claimTag = claim_tag
    console.log(data)
  
    const r = await callHook({url, uid:'lost-and-found', params:data, mode});
    //console.log(r);

    setSend(true);
  }

  if (isSend) {
    return (
      <div className={"py-6 bg-color-1"} id="generator">
		    <div className={"container text-center"}>
          {isFirst ? <h1 className="h2 mb-5 text-center" id="title">{title}</h1> : <h2 className="mb-5 text-center">{title}</h2>}
            <div className={"py-5"} id="try">
		          <div className={"container"}>
                <div className="text-center col-12 col-lg-8 mx-auto">
                  {!isError && (
                  <>
                    <h3>Thank You!</h3>
                    <p>We will pass the information on to the owner of the item.</p>
                  </>
                  )}
                  {isError && (
                  <>
                    <h3>An error has occurred</h3>
                    <p>Please try again later, server is not available now.</p>
                  </>
                  )}
                  <div className="col-auto">
                    <button onClick={()=>setSend(false)} className={"btn btn-sm text-uppercase btn-primary"}>{isError ? "Try again" : "Fill out the form again"}</button>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }

  return (
    <div className={"py-6 bg-color-1"} id="generator">
		  <div className={"container text-center"}>
        {isFirst ? <h1 className="h2 mb-5 text-center" id="title">{title}</h1> : <h2 className="mb-5 text-center">{title}</h2>}
        <div className="col-12 col-md-9 col-lg-7 mx-auto row align-items-center">
          {lostId &&
            <form id="bitcoin" className="text-center col" onSubmit={handleSubmit(onSubmit)}>
              <div className="">
                <input
                  placeholder="ID"
                  value={lostId}
                  readonly
                  className={'form-control form-disabled' + " " + (errors.full && "is-invalid")}
                  {...register('id', {required: false})}
                />
              </div>
              <div className="mt-4">
                <input
                  placeholder="Your name (optional)"
                  className={'form-control' + " " + (errors.full && "is-invalid")}
                  {...register('name', {required: false})}
                />
              </div>
              <div className="mt-4">
                <input
                  placeholder="Your email"
                  className={'form-control' + " " + (errors.full && "is-invalid")}
                  {...register('email', {required: true})}
                />
              </div>
              <div className="mt-4">
                <input
                  placeholder="Your phone (optional)"
                  className={'form-control' + " " + (errors.full && "is-invalid")}
                  {...register('phone', {required: false})}
                />
              </div>
              {!claim_tag &&
              <div className="mt-4">
                <textarea
                  placeholder="Enter your message"
                  className={'form-control' + " " + (errors.full && "is-invalid")}
                  rows="3"
                  maxLength="2000"
                  {...register('message', {required: true})}
                />
              </div>
              }
              <div className={"d-flex justify-content-center mt-4"}>
                <label className="form-check-label" for="sendData"><input className="form-check-input me-2" type="checkbox" value="" id="sendData"/><span className="fs-0875">{!claim_tag ? "I agree to share my contact information with the owner of the found item (optional)." : "I agree to share my contact information with whoever finds my item (optional)."}</span></label>
              </div>
              <div className={"d-flex justify-content-center mt-4"}>
                <label className="form-check-label" for="termsCheck"><input className="form-check-input me-2" type="checkbox" value="" id="termsCheck" required/><span className="fs-0875">I agree to the <a href="/terms/">Terms of Service</a>, <a href="/privacy-policy/">Privacy Policy</a> and Use of Cookies.</span></label>
              </div>
              <input type="submit" value="Submit form" className={"mt-4 btn btn-sm btn-primary text-uppercase"}/>
            </form>
          }
          {!lostId &&
            <div>
              <div class="loader mx-auto"></div>
              <h3 className="mt-4">Loading</h3>
              <p>Waiting for identifier</p>
            </div>
          }
        </div>
		  </div>
	  </div>
  )
}

export const query = graphql`
  fragment PageDataBodyLostAndFound on PrismicPageDataBodyLostAndFound {
    primary {
      title
      claim_tag
    }
  }
`
