import * as React from 'react'
import { graphql } from 'gatsby'

export const YohnPremium = ({ slice }) => {
  const {title, btn_label, btn_link, img_1, img_2} = slice.primary
  const link = `${btn_link.url}/`
  return (
    <div className={"pt-5 bg-color-2"}>
		  <div className={"container bg-dark py-5 rounded-4"}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h3 className="text-white text-center mb-0">{title}</h3>
          <div className="row py-5 g-5 justify-content-around">
            <div className="col text-center px-5">
              <img src={img_1.url} width="192" alt={"QR code 1"}/>
            </div>
            <div className="col text-center px-5">
              <img src={img_2.url} width="192" alt={"QR code 2"}/>
            </div>
          </div>
          <a className="btn btn-primary text-uppercase" href={link}>{btn_label}</a>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyYohnPremium on PrismicPageDataBodyYohnPremium {
    primary {
      title
      btn_label
      btn_link {
        url
      }
      img_1 {
        url
      }
      img_2 {
        url
      }
    }
  }
`
