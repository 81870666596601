import * as React from 'react'
import { graphql } from 'gatsby'

export const QrLinks = ({ slice }) => {
  const buttons = slice.items.map((item, index) => {
    let link = item.btn_link.url;
    if (!link.endsWith('/')) link += '/';
    return (
      <div key={index} className="col-12 col-md-6 col-lg-4">
        <a href={link} className="d-flex justify-content-between align-items-center text-white text-decoration-none pb-2 border-bottom border-white">
          <div className="d-flex align-items-center">
            <img src={item.btn_icon.url} alt={item.btn_label} className="me-2" height="16" width="16" />
            <h6 className="text-uppercase mb-0 ">{item.btn_label}</h6>
          </div>
          <img src={"../../icons/arrow-r-white.png"} alt="Link arrow icon" className="ms-3" height="16"/>
        </a>
      </div>
    )
  })

  return (
    <div className={"py-5 bg-color-2"}>
		  <div className={"container pb-3"}>
        <div className="row g-5 justify-content-start">
          {buttons}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyQrLinks on PrismicPageDataBodyQrLinks {
    items {
      btn_label
      btn_link {
        url
      }
      btn_icon {
        url
        alt
      }
    }
  }
`
