import * as React from 'react'
import { graphql } from 'gatsby'
import * as prismicH from "@prismicio/helpers";
import { PrismicRichText } from '@prismicio/react'

export const ImagesAndText = ({ slice }) => {
  const {title, reverse, secondary_bg} = slice.primary

  let evenOrOdd = 0

  if (reverse) {
    evenOrOdd = 1
  }

  const imgsAndText = slice.items.map((item, index) => {

    let img_url = item.img.url
  
    if (new URL(img_url).pathname.endsWith(".gif")) {
      img_url= prismicH.asImageSrc(item.img, {auto: "compress"})
    }

    return (
      <div key={index} className="row mb-5">
				<div className={"col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0" + " " + ((index % 2 != evenOrOdd) && "order-lg-2")}>
					<h3>{item.title}</h3>
					<PrismicRichText field={item.text.richText} />
				</div>
				<div className={"col-lg-6 d-flex flex-column align-items-center" + " " + ((index % 2 != 0) && "order-lg-1")}>
					<img src={img_url} className="img-fluid" alt={item.img.alt} height="456" width="456" loading="lazy"/>
				</div>
			</div>
    )
  })

  return (
    <div className={"py-6" + " " + (secondary_bg && "bg-color-1")} id="why">
			<div className="container">
				<h2 className="text-center mb-5">{title}</h2>
        {imgsAndText}
			</div>
		</div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyImagesAndText on PrismicHomepageDataBodyImagesAndText {
    primary {
      secondary_bg
      title
      reverse
    }
    items {
      title
      text {
        richText
      }
      img {
        url
        alt
      }
    }
  }
  fragment PageDataBodyImagesAndText on PrismicPageDataBodyImagesAndText {
    primary {
      secondary_bg
      title
      reverse
    }
    items {
      title
      text {
        richText
      }
      img {
        url
        alt
      }
    }
  }
`
