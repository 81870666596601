import * as React from 'react';
import { useContext, useEffect, useState,useReducer,useMemo, useRef } from 'react';
import { useForm } from "react-hook-form";
// import validator from 'validator';
var validator = require("email-validator");

import settings from '../settings.json';
import {getFromApi} from '../utils/utils';

export default function ShortHookForm() {
  const [isSend, setSend] = useState(false);
  const [isError, setError] = useState(false);
  const [isEmailVisible, setIsEmailVisible] = useState(false);

  const onShortenClick = () => {
	  setIsEmailVisible(!isEmailVisible);
	};

  const { register, setValue, handleSubmit, watch, formState: { errors, isSubmitted } } = useForm({
    mode: "onChange"
  });
  const onSubmit = async (data) => {
    //console.log('settings: ', settings);
    //console.log('data: ', data);
    const url = settings.backend + settings.forms;
    const r = await getFromApi({url, params:data, method:'POST'});
    //console.log(r);
    if (r && r.success === true) {
      setError(false);
    } else {
      setError(true);
    }
    setValue("full", "");
    setSend(true);
  }

  if (isSend) {
    return (
      <div className="text-center col-12 col-lg-8 mx-auto">
        {!isError && (
        <>
          <h3>Thank You!</h3>
          <p>We have sent a short link to your e-mail.</p>
        </>
        )}
        {isError && (
        <>
          <h3>An error has occurred</h3>
          <p>Please try again later, server is not available now.</p>
        </>
        )}
        <div className="col-auto">
          <button onClick={()=>setSend(false)} className={"btn btn-sm text-uppercase btn-primary"}>{isError ? "Try again" : "Shorten another link"}</button>
        </div>
      </div>
    );
  }
  // console.log(watch("example")); // watch input value by passing the name of it
  //console.log('errors: ', errors)
  return (
    <div className="col-12 col-md-9 col-lg-7 mx-auto row align-items-center">
      <form id="short-form" className="text-center col" onSubmit={handleSubmit(onSubmit)}>
        {/* <h1>Try it yourself!</h1> */}
        <div className="">
          <input
            id="shorten-form-url"
            placeholder="Your link here"
            className={'form-control' + " " + (errors.full && "is-invalid")}
            {...register('full', {required: true})}
          />
          <div className="invalid-feedback" wfd-invisible="true">{'Required!'}</div>
        </div>
        <div className={"mt-4" + " " + (!isEmailVisible && "d-none")}>
          <input
            className={'form-control' + " " + (errors.email && "is-invalid")}
            placeholder="Your email address"
            {...register('email', {required: true, validate: (input) => validator.validate(input)})}
            // aria-invalid={errors.mail ? "true" : "false"}
          />
          <div className="invalid-feedback" wfd-invisible="true">{'Invalid email address'}</div>
          <div className="text-start mt-3 fs-0875">Type in or paste your email address here and we will send your short URL to you right away.</div>
        </div>
        <div className={"form-check text-start mt-4" + " " + (!isEmailVisible && "d-none")}>
          <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" required/>
          <label className="form-check-label" for="flexCheckChecked"><span className="fs-0875">I agree to the <a href="/terms/">Terms of Service</a>, <a href="/privacy-policy/">Privacy Policy</a> and Use of Cookies.</span></label>
        </div>
        <input type="submit" value="Get short URL" className={"mt-4 btn btn-sm btn-primary text-uppercase" + " " + (!isEmailVisible && "d-none")}/>
      </form>
      <div className={"text-center col-auto" + " " + (isEmailVisible && "d-none")}>
        <button onClick={onShortenClick} className={"btn btn-sm btn-primary align-center text-uppercase"}>Shorten URL</button>
      </div>
    </div>
  );
}