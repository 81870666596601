import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

export const Pricing = ({ slice }) => {
  const {title} = slice.primary

  const pricing = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-12 col-lg-6">
				<div className="card h-100 box-shadow">
					<div className="card-body d-flex flex-column align-content-between py-4">
						<h5 className="card-title text-uppercase text-center">{item.title}</h5>
						<p className="h1 text-center mb-0">€{item.price}</p>
						{item.text_after_price &&
							<p className="text-center fs-0875 opacity-75 mb-0">{item.text_after_price}</p>
						}
						<hr/>
						<PrismicRichText field={item.main_features.richText} />
						<ul className="fa-ul">			
							{item.extra_feature_1 &&
							<li className="fw-bold"><span className="fa-li"><i className="fas fa-check icon-main"></i></span>{item.extra_feature_1}
								<OverlayTrigger
        				  trigger="click"
        				  overlay={
        				    <Popover id={`popover-positioned`}>
        				      <Popover.Header as="h3">{item.extra_feature_1_popover_label}</Popover.Header>
        				      <Popover.Body>{item.extra_feature_1_popover_text}</Popover.Body>
        				    </Popover>
        				  }
        				>
        				  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="ms-1 bi bi-question-circle-fill" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/></svg>
        				</OverlayTrigger>
								</li>
							}
							{item.extra_feature_2 &&
							<li className="fw-bold"><span className="fa-li"><i className="fas fa-check icon-main"></i></span>{item.extra_feature_2}
								<OverlayTrigger
        				  trigger="click"
        				  overlay={
        				    <Popover id={`popover-positioned`}>
        				      <Popover.Header as="h3">{item.extra_feature_2_popover_label}</Popover.Header>
        				      <Popover.Body>{item.extra_feature_2_popover_text}</Popover.Body>
        				    </Popover>
        				  }
        				>
        				  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="ms-1 bi bi-question-circle-fill" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/></svg>
        				</OverlayTrigger>
								</li>
								}
						</ul>
						<div className="h-100 d-flex flex-column justify-content-end">
							<a href={item.btn_link.url} target="_blank" className="btn btn-block btn-primary text-uppercase">Order Now</a>
						</div>
					</div>
				</div>
			</div>
    )
  })

  return (
    <div className="pricing bg-color-3 py-6" id="pricing">
			<div className="container">
				<h2 className="text-center text-white mb-5">Pricing</h2>
				<div className="row g-3">	
          {pricing}
				</div>
			</div>
		</div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyPricing on PrismicHomepageDataBodyPricing {
    primary {
      title
    }
    items {
      title
      price
			text_after_price
			main_features {
				richText
			}
			extra_feature_1
			extra_feature_1_popover_label
			extra_feature_1_popover_text
			extra_feature_2
			extra_feature_2_popover_label
			extra_feature_2_popover_text
			btn_link {
				url
			}
    }
  }
	fragment PageDataBodyPricing on PrismicPageDataBodyPricing {
    primary {
      title
    }
    items {
      title
      price
			text_after_price
			main_features {
				richText
			}
			extra_feature_1
			extra_feature_1_popover_label
			extra_feature_1_popover_text
			extra_feature_2
			extra_feature_2_popover_label
			extra_feature_2_popover_text
			btn_link {
				url
			}
    }
  }
`
